<div>
  <ng-container [ngSwitch]="size">
    <div class="large" [ngClass]="{'column' : orientation === 'top-bottom', 'column-reverse' : orientation !== 'top-bottom'}" *ngSwitchCase="'large'">
      <h1 class="value" [ngClass]="{'link': dataType === 'link'}" (click)="handleLinkClicked($event)" matTooltip="{{getToolTip(value)}}">{{formatData(value) ?? '--'}}</h1>
      <h4 class="label">{{label}}</h4>
      <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
    </div>
    <div class="small" [ngClass]="{'column' : orientation === 'top-bottom', 'column-reverse' : orientation !== 'top-bottom'}" *ngSwitchCase="'small'">
      <h4 class="value" [ngClass]="{'link': dataType === 'link'}" (click)="handleLinkClicked($event)" matTooltip="{{getToolTip(value)}}">{{formatData(value) ?? '--'}}</h4>
      <span class="label">{{label}}</span>
      <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
    </div>
    <div class="medium" [ngClass]="{'column' : orientation === 'top-bottom', 'column-reverse' : orientation !== 'top-bottom'}" *ngSwitchDefault>
      <h2 class="value" [ngClass]="{'link': dataType === 'link'}" (click)="handleLinkClicked($event)" matTooltip="{{getToolTip(value)}}">{{formatData(value) ?? '--'}}</h2>
      <span class="label">{{label}}</span>
      <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
    </div>
    <div class="x-small" [ngClass]="{'column' : orientation === 'top-bottom', 'column-reverse' : orientation !== 'top-bottom'}" *ngSwitchCase="'x-small'">
      <span class="value" [ngClass]="{'link': dataType === 'link'}" (click)="handleLinkClicked($event)" matTooltip="{{getToolTip(value)}}">{{formatData(value) ?? '--'}}</span>
      <span class="label">{{label}}</span>
      <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #warningTemplate>
  <div *ngIf="showWarning" class="warning-message">
    <mat-icon class="warning-icon">warning</mat-icon>
    <span>{{warning}}</span>
  </div>
</ng-template>
