import { Page } from './Page';
import { CashAppRemitLineItemTotals } from './CashAppRemitLineItemTotals';
import { ManualRemitLineItem } from './ManualRemitLineItem';

export class ManualRemitLineItemData {
  page: Page<ManualRemitLineItem>;
  totals: CashAppRemitLineItemTotals;

  constructor(page?: Page<ManualRemitLineItem>, totals?: CashAppRemitLineItemTotals) {
    this.page = page ?? null;
    this.totals = totals ?? null;
  }
}
