import { GridColumn } from 'src/app/Components/App Components/data-grid/Models/LsDataGridConfig';
import { ActivePage } from '../ActivePage';
import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';
import { SortDirection } from '@limestone/ls-shared-modules';

export class SellerOnboardingConfig extends ReportConfig {
  gridColumns: GridColumn[];
  reportType: ReportTypes;
  activePage: ActivePage;

  constructor() {
    super();
    this.reportType = ReportTypes.SELL_ONBD;
    this.gridColumns = [
      {
        label: 'Facility Group Name',
        value: 'facilityGroupName',
        dataType: 'text'
      },
      {
        label: 'Facility Group ID',
        value: 'facilityGroupId',
        dataType: 'text'
      },
      {
        label: 'Seller Name',
        value: 'sellerName',
        dataType: 'text'
      },
      {
        label: 'Seller Status',
        value: 'sellerStatus',
        dataType: 'text'
      },
      {
        label: 'Source System Seller ID',
        value: 'sourceSystemSellerId',
        dataType: 'text'
      },
      {
        label: 'Debtor Name',
        value: 'debtorName',
        dataType: 'text'
      },
      {
        label: 'Source System Debtor ID',
        value: 'sourceSystemDebtorId',
        dataType: 'text'
      },
      {
        label: 'Currency',
        value: 'currency',
        dataType: 'text'
      },
      {
        label: 'Create Date',
        value: 'createDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy',
        enableFilter: true
      },
      {
        label: 'Status',
        value: 'status',
        dataType: 'text'
      },
      {
        label: 'Facility ID',
        value: 'facilityId',
        dataType: 'text'
      },
      {
        label: 'Instrument Source Channel',
        value: 'instrumentSourceChannel',
        dataType: 'text'
      }
    ];
    this.activePage = this.buildActivePageConfig([{ active: 'facilityId', direction: SortDirection.ASC }]);
  }
}
