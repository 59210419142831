import { Component, Input } from '@angular/core';
import { GeneralUtils } from '../../../Utils/GeneralUtils';

export interface Summary {
  label: string;
  value: string;
  columnSpan?: number;
}

@Component({
  selector: 'ls-summary-bar',
  templateUrl: './summary-bar.component.html',
  styleUrls: ['./summary-bar.component.scss']
})
export class SummaryBarComponent {
  @Input({ required: true }) summaries!: Summary[];
  protected readonly Math = Math;

  getColumnSpan(summary: Summary, summaries: Summary[]): number {
    if (GeneralUtils.exists(summary.columnSpan)) {
      return summary.columnSpan;
    }
    if (summaries.length > 4) {
      return 3;
    }
    return 12 / summaries.length;
  }
}
