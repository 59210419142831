import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';
import { SortDirection } from '@limestone/ls-shared-modules';
export class LineItemsParticipantDisbursementConfig extends ReportConfig {
  constructor() {
    super();
    this.reportType = ReportTypes.LI_PT_DIS;
    this.gridColumns = [
      {
        label: 'Effective Date',
        value: 'effectiveDate',
        dataType: 'date',
        dateTimeFormat: 'MM-dd-yyyy',
        enableFilter: true
      },
      {
        label: 'Recipient Company',
        value: 'recipientCompany',
        dataType: 'text'
      },
      {
        label: 'Remittance Reference ID',
        value: 'id',
        dataType: 'number'
      },
      {
        label: 'Seller Name',
        value: 'sellerName',
        dataType: 'text'
      },
      {
        label: 'Debtor Name',
        value: 'debtorName',
        dataType: 'text'
      },
      {
        label: 'Instrument Type',
        value: 'instrumentType',
        dataType: 'text'
      },
      {
        label: 'Document ID',
        value: 'documentId',
        dataType: 'text'
      },
      {
        label: 'Currency',
        value: 'currency',
        dataType: 'text'
      },
      {
        label: 'Line Item Purpose',
        value: 'lineItemPurpose',
        dataType: 'text'
      },
      {
        label: 'Line Item Type',
        value: 'lineItemType',
        dataType: 'text'
      },
      {
        label: 'Line Allocated Amount',
        value: 'lineItemAllocatedAmount',
        dataType: 'number'
      },
      {
        label: 'Remittance Net Amount',
        value: 'remittanceNetAmount',
        dataType: 'number'
      },
      {
        label: 'Remittance Allocated Amount',
        value: 'remittanceAllocatedAmount',
        dataType: 'number'
      },
      {
        label: 'Remittance Unallocated Amount',
        value: 'remittanceUnallocatedAmount',
        dataType: 'number'
      }
    ];
    this.activePage = this.buildActivePageConfig([
      { active: 'effectiveDate', direction: SortDirection.ASC },
      { active: 'id', direction: SortDirection.ASC }
    ]);
  }
}
