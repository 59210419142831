import { StoreUpdate } from '../../../Models/StoreUpdate';
import { StoreUpdateFacilityConfig } from '../../../Models/StoreUpdateFacilityConfig';
import { take, tap } from 'rxjs/operators';
import {
  FacilityActions,
  FacilityConfigurationActions,
  FacilityConfigurationSelectors,
  FacilitySelectors
} from '../OpsStateManagement';
import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { StoreUpdateFacility } from '../../../Models/StoreUpdateFacility';
import { GeneralUtils } from '../../../../Utils/GeneralUtils';

@Injectable()
export class StoreUpdateService {
  constructor(
    private store: Store,
    private facilitySelectors: FacilitySelectors,
    private facilityConfigSelectors: FacilityConfigurationSelectors,
    private zone: NgZone
  ) {}
  public processStoreUpdate(storeUpdate: StoreUpdate) {
    switch (storeUpdate.type) {
      case 'FacilityConfig':
        this.facilityConfigStoreUpdate(storeUpdate.data as StoreUpdateFacilityConfig);
        break;
      case 'Facility':
        this.facilityStoreUpdate(storeUpdate.data as StoreUpdateFacility);
        break;
    }
  }

  private facilityConfigStoreUpdate(data: StoreUpdateFacilityConfig) {
    forkJoin({
      facility: this.store.select(this.facilitySelectors.selectActiveFacility).pipe(
        take(1),
        tap((activeFacility) => {
          if (GeneralUtils.exists(activeFacility?.id) && activeFacility?.id === data.FacilityId) {
            this.zone.run(() => {
              this.store.dispatch(FacilityActions.getFacility({ id: activeFacility.id }));
              this.store.dispatch(
                FacilityConfigurationActions.getFacilityConfigDates({
                  id: 0,
                  facilityId: activeFacility.id
                })
              );
            });
          }
        })
      ),
      facilityConfig: this.store.select(this.facilityConfigSelectors.selectActiveFacilityConfig).pipe(
        take(1),
        tap((activeFacilityConfig) => {
          if (activeFacilityConfig?.id === data.FacilityConfigId) {
            this.zone.run(() =>
              this.store.dispatch(
                FacilityConfigurationActions.getFacilityConfig({
                  id: data.FacilityConfigId,
                  facilityId: activeFacilityConfig.facilityId
                })
              )
            );
          }
        })
      )
    }).subscribe();
  }

  private facilityStoreUpdate(data: StoreUpdateFacility) {
    this.store
      .select(this.facilitySelectors.selectActiveFacility)
      .pipe(
        take(1),
        tap((activeFacility) => {
          if (GeneralUtils.exists(activeFacility?.id) && activeFacility?.id === data.Id) {
            this.zone.run(() => {
              this.store.dispatch(FacilityActions.getFacility({ id: activeFacility.id }));
            });
          }
        })
      )
      .subscribe();
  }
}
