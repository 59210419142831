import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, Page, ParticipantGroupList, ParticipantGroup } from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';

export abstract class ParticipantGroupActions extends LsAction {
  static PARTICIPANT_GROUPS = 'PARTICIPANT_GROUPS';
  static PARTICIPANT_GROUP = 'PARTICIPANT_GROUP';
  static PARTICIPANT_GROUP_ACTIVE_PAGE = 'PARTICIPANT_GROUPS_ACTIVE_PAGE';
  static ACTIVE_PARTICIPANT_GROUP = 'ACTIVE_PARTICIPANT_GROUP';

  static getParticipantGroups = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUPS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setParticipantGroups = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUPS, ActionType.SET),
    props<{ page: Page<ParticipantGroupList> }>()
  );

  static setParticipantGroupsActivePage = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUP_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static clearParticipantGroups = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUPS, ActionType.CLEAR)
  );

  static setActiveParticipantGroup = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.ACTIVE_PARTICIPANT_GROUP, ActionType.SET),
    props<{ activeParticipantGroup: ParticipantGroup }>()
  );

  static getActiveParticipantGroup = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.ACTIVE_PARTICIPANT_GROUP, ActionType.GET),
    props<{ id: string | number }>()
  );

  static updateParticipantGroup = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUP, ActionType.UPDATE),
    props<{ participantGroup: ParticipantGroup }>()
  );

  static createParticipantGroup = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUP, ActionType.SAVE),
    props<{ participantGroup: ParticipantGroup }>()
  );

  static participantGroupSaveSuccessful = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUP, ActionType.COMPLETE)
  );

  static participantGroupSaveUnSuccessful = createAction(
    ParticipantGroupActions.getActionName(ParticipantGroupActions.PARTICIPANT_GROUP, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getAutocompleteParticipantGroups = createAction(
    ParticipantGroupActions.getActionName(
      `AUTOCOMPLETE\/${ParticipantGroupActions.PARTICIPANT_GROUPS}`,
      ActionType.GET
    ),
    props<{ name: string; currency?: string; operationsCenter: string; product?: string; pageRequest?: PageRequest }>()
  );

  static setAutocompleteParticipantGroups = createAction(
    ParticipantGroupActions.getActionName(
      `AUTOCOMPLETE\/${ParticipantGroupActions.PARTICIPANT_GROUPS}`,
      ActionType.SET
    ),
    props<{ page: Page<ParticipantGroup> }>()
  );
}
