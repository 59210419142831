import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';
import { SortDirection } from '@limestone/ls-shared-modules';

export class SellerInstrumentListConfig extends ReportConfig {
  constructor() {
    super();
    this.reportType = ReportTypes.SELL_INST;
    this.gridColumns = [
      {
        label: 'Seller Name',
        value: 'sellerName',
        dataType: 'text'
      },
      {
        label: 'Debtor Name',
        value: 'debtorName',
        dataType: 'text'
      },
      {
        label: 'Instrument ID',
        value: 'instrumentId',
        dataType: 'text'
      },
      {
        label: 'Instrument Type',
        value: 'instrumentType',
        dataType: 'text'
      },
      {
        label: 'Document ID',
        value: 'documentId',
        dataType: 'text'
      },
      {
        label: 'Currency',
        value: 'currency',
        dataType: 'text'
      },
      {
        label: 'Issue Date',
        value: 'issueDate',
        dataType: 'date',
        enableFilter: true
      },
      {
        label: 'Document Due Date',
        value: 'documentDueDate',
        dataType: 'date'
      },
      {
        label: 'Net Amount',
        value: 'netAmount',
        dataType: 'number'
      },
      {
        label: 'Eligibility Status',
        value: 'eligibilityStatus',
        dataType: 'text'
      }
    ];
    this.activePage = this.buildActivePageConfig([{ active: 'issueDate', direction: SortDirection.ASC }]);
  }
}
