import { TextStyle } from './Enums';

export class DisplayConfig {
  /// <summary>
  /// The text to set on the element. If not provided, it will use elements textContent. InnerHTML is supported;
  //  however, it is recommended to be used for inline HTML only.
  /// </summary>
  text?: string;
  /// <summary>
  /// The display setting for the element. If not provided, it will set to display="block".
  /// </summary>
  display?: boolean = true;
  /// <summary>
  /// The text style to apply to the element. https://app.zeplin.io/project/65a7fa18223ffbad2fa3e2a2/styleguide/textstyles
  /// </summary>
  textStyle?: TextStyle;

  constructor(text?: string, textStyle?: TextStyle, display = true) {
    this.text = text;
    this.display = display;
    this.textStyle = textStyle;
  }
}
