import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, Page, Report } from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';

export abstract class GeneratedReportsActions extends LsAction {
  /* eslint-disable */
  static GENERATED_REPORTS: Readonly<string> = 'GENERATED_REPORTS';
  static REPORT_FILE: Readonly<string> = 'REPORT_FILE';
  static GENERATED_REPORTS_ACTIVE_PAGE: Readonly<string> = 'GENERATED_REPORTS_ACTIVE_PAGE';
  /* eslint-enable */

  static getReports = createAction(
    GeneratedReportsActions.getActionName(GeneratedReportsActions.GENERATED_REPORTS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setReports = createAction(
    GeneratedReportsActions.getActionName(GeneratedReportsActions.GENERATED_REPORTS, ActionType.SET),
    props<{ page: Page<Report> }>()
  );

  static clearReports = createAction(
    GeneratedReportsActions.getActionName(GeneratedReportsActions.GENERATED_REPORTS, ActionType.CLEAR)
  );

  static downloadReport = createAction(
    GeneratedReportsActions.getActionName(GeneratedReportsActions.REPORT_FILE, ActionType.GET),
    props<{ report: Report }>()
  );

  static getReportsActivePage = createAction(
    GeneratedReportsActions.getActionName(GeneratedReportsActions.GENERATED_REPORTS_ACTIVE_PAGE, ActionType.GET)
  );

  static setReportsActivePage = createAction(
    GeneratedReportsActions.getActionName(GeneratedReportsActions.GENERATED_REPORTS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );
}
