import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class AdjustmentType extends GenericDropdownOption<string> {
  public static readonly PARTIAL: string = 'PARTIAL';
  public static readonly SHORT: string = 'SHORT';
  constructor(id?: string, name?: string, isActive?: boolean) {
    super(id, name, isActive);
  }

  static from(lineItemAdjustmentCode: string | AdjustmentType) {
    return typeof lineItemAdjustmentCode === 'string'
      ? new AdjustmentType(
          lineItemAdjustmentCode.toUpperCase(),
          lineItemAdjustmentCode.charAt(0).toUpperCase() + lineItemAdjustmentCode.slice(1).toLowerCase(),
          true
        )
      : lineItemAdjustmentCode;
  }
}
