import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ConditionalRequiredValidation,
  DateValidation,
  GridColumn,
  NumberValidation,
  RequiredValidation,
  SelectValidation,
  TextValidation
} from '../Models/LsDataGridConfig';
import { CurrencyPipe, DatePipe, DecimalPipe, formatDate } from '@angular/common';
import { DataGridService } from '../Services/data-grid.service';
import { LargeCurrencyDisplayPipe, ShowHiddenCharactersPipe, TimePipe } from '../../../../../Utils/Pipes';
import { EditComponentBase } from '../../../EditComponentBase';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AutocompleteEvent } from '../Models/AutoCompleteEvent';
import { deepEqual } from 'fast-equals';
import { CustomValidators } from '../../../../../Utils/CustomValidators';
import { map, takeUntil } from 'rxjs/operators';
import { DataChangeEvent } from '../Models/DataChangeEvent';
import { SelectChangeEvent } from '../Models/SelectChangeEvent';
import { createLsTextValidatorArray } from '../../../../../Utils/Validators/ls-text-validator-array.validators';
import { createLsNumberValidatorArray } from '../../../../../Utils/Validators/ls-number-validator-array.validators';
import { createLsDateValidatorArray } from '../../../../../Utils/Validators/ls-date-validator-array.validators';
import { DropdownOption, GenericDropdownOption } from '@limestone/ls-shared-modules';
import { DateTime } from 'luxon';
import { OpenedChangeEvent } from '../Models/OpenedChangeEvent';
import { debounceTime } from 'rxjs';
import { GeneralUtils } from '../../../../../Utils/GeneralUtils';

@Component({
  selector: 'ls-data-grid-cell',
  templateUrl: './grid-cell.component.html',
  styleUrls: ['./grid-cell.component.scss']
})
export class GridCellComponent extends EditComponentBase {
  @Input() element: any;
  @Input() set column(col: GridColumn) {
    this.dropdownOptions = col.dropdownOptions;
    this.columnData = col;
  }
  get column() {
    return this.columnData;
  }
  @Input() row: any;
  @Input() autoCompleteOptions: DropdownOption[] = [];

  private cellIsEditing: boolean = false;
  @Input() set isEditing(value: boolean) {
    this.cellIsEditing = value && !!this.column.enableEdit;
    if (this.cellIsEditing) {
      this.buildForm();
    }
  }
  get isEditing() {
    if (typeof this.column.enableEdit === 'function') {
      return this.cellIsEditing && this.column.enableEdit(this);
    }
    return this.cellIsEditing && this.column.enableEdit;
  }

  public dropdownOptions: DropdownOption[] = [];
  private columnData: GridColumn;

  @Output() autocompleteChange: EventEmitter<AutocompleteEvent> = new EventEmitter<AutocompleteEvent>();
  @Output() selectChange: EventEmitter<SelectChangeEvent> = new EventEmitter<SelectChangeEvent>();
  @Output() openedChange: EventEmitter<OpenedChangeEvent> = new EventEmitter<OpenedChangeEvent>();
  @Output() dataChange: EventEmitter<DataChangeEvent> = new EventEmitter<DataChangeEvent>();
  @Output() valueChange: EventEmitter<{ column: GridColumn; value: any }> = new EventEmitter();
  @Output() formIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cellButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() linkClick: EventEmitter<LinkData> = new EventEmitter<LinkData>();

  constructor(
    private currencyPipe: CurrencyPipe,
    protected dataGridService: DataGridService,
    private datePipe: DatePipe,
    private timePipe: TimePipe,
    private decimalPipe: DecimalPipe,
    private showHiddenCharactersPipe: ShowHiddenCharactersPipe,
    private largeCurrencyDisplayPipe: LargeCurrencyDisplayPipe,
    private customValidators: CustomValidators
  ) {
    super(null, null, null);
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.statusChanges
      .pipe(
        takeUntil(this.componentTeardown$),
        map(() => this.formIsValid.emit(this.formGroup.valid))
      )
      .subscribe();
  }

  /**
   * Gets the display value. Will apply transformations if applicable.
   *
   * @return string | number formatted cell display value.
   */
  public getDisplayValue(): string | number {
    let displayVal = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
    if (this.column.currencyCode) {
      displayVal = this.currencyPipe.transform(displayVal, this.column.currencyCode);
    } else if (this.column.dataType === 'checkbox') {
      displayVal = displayVal ?? this.element[this.column.value] ? 'done' : null;
    } else if (this.column.dataType === 'date') {
      const timezone = this.column.dateTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (displayVal) {
        if (displayVal instanceof DateTime) {
          displayVal = displayVal.setZone(timezone).toFormat('yyyy-MM-dd');
        } else {
          displayVal = displayVal ? DateTime.fromISO(displayVal, { zone: timezone }).toFormat('yyyy-MM-dd') : null;
        }
      }
      if (GeneralUtils.exists(displayVal) && this.column.dateTimeFormat === 'mediumDate') {
        return formatDate(displayVal, 'mediumDate', 'en-US', timezone);
      }
    } else if (this.column.dataType === 'select') {
      if (displayVal) {
        if (typeof displayVal === 'object') {
          displayVal = GeneralUtils.exists(displayVal['name']) ? displayVal['name'] : '';
        }
      }
    } else if (this.column.dataType === 'time') {
      displayVal = this.timePipe.transform(displayVal);
    } else if (this.column.dataType === 'datetime') {
      if (displayVal) {
        const format = this.column.dateTimeFormat ?? 'yyyy-MM-dd hh:mm aa';
        const timezone = this.column.dateTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
        displayVal = this.datePipe.transform(displayVal, format, timezone);
      }
    } else if (displayVal instanceof DropdownOption || displayVal instanceof GenericDropdownOption) {
      displayVal = displayVal.label();
    } else if (this.column.dataType === 'percent') {
      displayVal = displayVal !== null ? displayVal * 100 : null;
    }
    if (this.column.validation instanceof NumberValidation) {
      if (this.column.dataType === 'number') {
        displayVal = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
        displayVal = this.largeCurrencyDisplayPipe.transform(displayVal, this.column.validation);
      } else {
        displayVal = this.transformDisplayValue(this.column.dataType, displayVal);
      }
    }
    return this.showHiddenCharactersPipe.transform(displayVal);
  }

  /**
   * Gets the tool tip value. Will apply transformations if applicable.
   *
   * @return string | number formatted cell tool tip value.
   */
  public getToolTip(): string | number {
    let displayVal = null;
    if (this.column.validation instanceof NumberValidation) {
      displayVal = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
      if (!(+displayVal > 1e12 || +displayVal < -1e12)) {
        displayVal = null;
      }
    }
    return this.showHiddenCharactersPipe.transform(displayVal);
  }

  /**
   * Returns transformed display value from Validation.
   *
   * @param validationType Validation type of the column.
   * @param value Value of the cell.
   * @private
   *
   * @return any formatted input value.
   */
  private transformDisplayValue(validationType: string, value: any): any {
    switch (validationType) {
      case 'percent':
      case 'number':
        const validation: NumberValidation = this.column.validation;
        const format = `1.${validation.numberOfDecimals}-${validation.numberOfDecimals}`;
        return this.decimalPipe.transform(value, format);
      default:
        return value;
    }
  }

  // ================ EDIT HANDLING ===================== //

  /**
   * Builds the form given the current element.
   *
   * @private
   */
  private buildForm(): void {
    let value = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
    if (this.column.dataType === 'percent') {
      value = value * 100;
      value = this.transformDisplayValue(this.column.dataType, value);
    }
    // Both o?.value() and value are base classes of DropdownOption, so it is safe to assume both will have an id property.
    let controlState;
    if (this.column.dataType === 'select') {
      controlState = this.column.dropdownOptions.find((o) => deepEqual(o?.value(), value));
    } else if (this.column.dataType === 'date' && value) {
      controlState = DateTime.fromISO(value, { zone: 'utc' }).toFormat('yyyy-MM-dd');
    } else {
      controlState = value;
    }
    const control = new UntypedFormControl(controlState);
    if (this.column.validation) {
      this.setValidators(control, this.column.dataType, this.column.validation);
    }
    if (this.formGroup.contains(this.column.value)) {
      this.formGroup.get(this.column.value).patchValue(value);
    } else {
      this.formGroup.addControl(this.column.value, control);

      control.valueChanges.pipe(takeUntil(this.componentTeardown$), debounceTime(300)).subscribe((newValue) => {
        this.dataGridService.setElementValueFromColumnValue(this.element, this.column.value, newValue);
        this.valueChange.emit({
          column: this.column,
          value: newValue
        });
      });
    }
  }

  /**
   * Dynamically sets validations based on column data type.
   *
   * @private
   */
  private setValidators(
    control: UntypedFormControl,
    editType: string,
    validators:
      | NumberValidation
      | DateValidation
      | TextValidation
      | SelectValidation
      | RequiredValidation
      | ConditionalRequiredValidation
  ): void {
    if (validators instanceof ConditionalRequiredValidation) {
      const dependentValues = [];
      for (const dependentColumnName of validators.dependentColumns) {
        dependentValues.push(this.dataGridService.getElementValueFromColumnValue(dependentColumnName, this.element));
      }

      if (validators.validator(dependentValues)) {
        control.setValidators([Validators.required]);
      } else {
        control.setValidators(null);
      }

      control.updateValueAndValidity();
      control.markAsTouched();
    } else {
      switch (editType) {
        case 'autocomplete':
        case 'text':
          const val = validators as TextValidation;
          control.setValidators(createLsTextValidatorArray(val));
          break;
        case 'percent':
        case 'number':
          const val2 = validators as NumberValidation;
          control.setValidators(createLsNumberValidatorArray(val2));
          break;
        case 'date':
          const val3 = validators as DateValidation;
          control.setValidators(createLsDateValidatorArray(val3, this.customValidators));
          break;
        case 'select':
          const val4 = validators as SelectValidation;
          if (val4.required) {
            control.setValidators(Validators.required);
          }
          break;
        case 'time':
          const val5 = validators as TextValidation;

          if (val5.required) {
            control.setValidators(Validators.required);
          }
          break;
        default:
          const val6 = validators as RequiredValidation;
          if (val6.required) {
            control.setValidators(Validators.required);
          }
          break;
      }
    }

    control.updateValueAndValidity();
  }

  /**
   * Applies changes to data when user hits enter or checks apply on the row.
   */
  public submitEdit(): void {
    if (this.formGroup.valid) {
      this.updateVal();
    }
  }

  /**
   * Updates source data with values from form and removes controls.
   *
   * @private
   */
  private updateVal() {
    try {
      let value = this.formGroup.value[this.column.value];
      if (this.column.dataType === 'percent') {
        value = parseFloat(value) / 100;
      }
      this.dataGridService.setElementValueFromColumnValue(this.element, this.column.value, value);
      this.emitData();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Changes value of AutoComplete to what user has selected.
   */
  public handleAutocompleteChange() {
    const acEvent: AutocompleteEvent = {
      element: null,
      column: this.column.value,
      value: this.formGroup.value[this.column.value]
    };
    this.autocompleteChange.emit(acEvent);
  }

  /**
   * Emits Selected Value When User Makes Selection
   */
  public handleSelectChange() {
    this.selectChange.emit({
      element: this.element,
      column: this.column,
      value: this.formGroup.value[this.column.value]
    });
  }

  /**
   * Emits Element Data When User Opens Select Box
   * @param open
   */
  public handleOpenedChange(open: boolean) {
    if (open) {
      this.openedChange.emit({
        element: this.element,
        column: this.column
      });
    }
  }

  /**
   * Disallows user to enter e or E on number inputs.
   *
   * @param input KeyboardEvent from input.
   *
   * @return boolean
   */
  public handleInput(input: KeyboardEvent): boolean {
    if (this.column.dataType === 'number' && (input.key === 'e' || input.key === 'E')) {
      return false;
    }
  }

  /**
   * Checks if the form has errors on the specified control.
   *
   * @param name Name of the control to be checked.
   */
  public controlHasErrors(name: string): boolean {
    const control = this.formGroup.get(name);
    if (control) {
      if (control.errors && !control.touched) {
        return false;
      }
      return control.errors != null;
    } else {
      return false;
    }
  }

  /**
   * Emits data when it has been modified and removes temporary unique identifier
   *
   * @param autoCompleteOption Auto complete option that was selected.
   * @private
   */
  private emitData(autoCompleteOption?: DropdownOption): void {
    const event = new DataChangeEvent(
      this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element),
      autoCompleteOption ?? null
    );
    this.dataChange.emit(event);
  }

  /**
   * Emits when the button cell is clicked.
   *
   * @param event Click event
   * @param index index of the menu button clicked
   */
  public emitCellButtonEvent(event: MouseEvent, index?: number) {
    event.stopPropagation();
    if (index >= 0) {
      this.column.menuButtons[index].handler(this.element);
    }
    this.cellButtonClick.emit(this.element);
  }

  public isCellButtonEnabled(column: GridColumn) {
    return typeof column.enableCellButton !== 'undefined' ? column.enableCellButton : true;
  }

  public autoCompleteDisplayValue(option: DropdownOption): string {
    return option && option.label() ? option.label() : '';
  }

  handleLinkClicked($event: MouseEvent) {
    this.linkClick.emit(new LinkData(this.column.link, this.element));
  }

  public getInputStep(): string {
    if (
      this.column.dataType === 'number' &&
      this.column.validation instanceof NumberValidation &&
      this.column.validation.numberOfDecimals === 2
    ) {
      return '0.01';
    }
    return '1';
  }

  public updateConditionalValidation(): void {
    if (this.column.validation instanceof ConditionalRequiredValidation && this.formGroup.contains(this.column.value)) {
      const control = this.formGroup.get(this.column.value);
      if (control && control instanceof UntypedFormControl) {
        this.setValidators(control, this.column.dataType, this.column.validation);
      }
    }
  }

  protected readonly NumberValidation = NumberValidation;
}

export class LinkData {
  link: string;
  linkData: any;
  constructor(link: string, linkData: any) {
    this.link = link;
    this.linkData = linkData;
  }
}
