export enum ReportTypes {
  AC_BUY_SEL = 'AC_BUY_SEL',
  AC_PT_CA = 'AC_PT_CA',
  LI_PT_DIS = 'LI_PT_DIS',
  LI_SEL_COL = 'LI_SEL_COL',
  LI_SEL_DIS = 'LI_SEL_DIS',
  MAT_PAYMT = 'MAT_PAYMT',
  PART_BAL = 'PART_BAL',
  RM_PT_COL = 'RM_PT_COL',
  RM_PT_DIS = 'RM_PT_DIS',
  RM_SEL_COL = 'RM_SEL_COL',
  RM_SEL_DIS = 'RM_SEL_DIS',
  SELL_INST = 'SELL_INST',
  SELL_ONBD = 'SELL_ONBD',
  SELL_POL = 'SELL_POL'
}
