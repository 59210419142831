import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, Company, Page } from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';

export abstract class CompanyActions extends LsAction {
  /* eslint-disable */
  static COMPANIES: Readonly<string> = 'COMPANIES';
  static COMPANY: Readonly<string> = 'COMPANY';
  static COMPANIES_ACTIVE_PAGE: Readonly<string> = 'COMPANIES_ACTIVE_PAGE';
  /* eslint-enable */

  static getCompanies = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANIES, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setCompanies = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANIES, ActionType.SET),
    props<{ page: Page<Company> }>()
  );

  static getCompany = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANY, ActionType.GET),
    props<{ id: number }>()
  );

  static setCompany = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANY, ActionType.SET),
    props<{ company: Company }>()
  );

  static clearCompanies = createAction(CompanyActions.getActionName(CompanyActions.COMPANIES, ActionType.CLEAR));

  static createCompany = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANY, ActionType.SAVE),
    props<{ company: Company }>()
  );

  static updateCompany = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANY, ActionType.UPDATE),
    props<{ company: Company }>()
  );

  static getAutocompleteCompanies = createAction(
    CompanyActions.getActionName(`AUTOCOMPLETE\/${CompanyActions.COMPANIES}`, ActionType.GET),
    props<{
      name: string;
      pageRequest?: PageRequest;
      role?: string;
      product?: string;
      currency?: string;
      companyId?: number;
      parentCompanyId?: number;
      includeSubCompanies?: boolean;
    }>()
  );

  static setAutocompleteCompanies = createAction(
    CompanyActions.getActionName(`AUTOCOMPLETE\/${CompanyActions.COMPANIES}`, ActionType.SET),
    props<{ page: Page<Company> }>()
  );

  static companySaveSuccessful = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANY, ActionType.COMPLETE)
  );

  static companySaveUnsuccessful = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANY, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getCompaniesActivePage = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANIES_ACTIVE_PAGE, ActionType.GET)
  );

  static setCompaniesActivePage = createAction(
    CompanyActions.getActionName(CompanyActions.COMPANIES_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );
}
