// @ts-ignore

import { PaymentMethod } from './PaymentMethod';
import { DateTime } from 'luxon';
import { DateUtils } from '../../Utils/DateUtils';
import { OperationsOwner } from './OperationsOwner';
import { CashAppInboundDetails } from './CashAppInboundDetails';

export class InboundRemitGrid {
  facilityGroupId: number;
  facilityGroupName: string;
  originatorId: number;
  originatorName: string;
  originatorRoleTypeName: string;
  proposedEffectiveDate: DateTime;
  compartmentId: number;
  compartmentName: string;
  receiptDate: DateTime;
  effectiveDate: DateTime;
  remittanceReferenceId: number;
  remittancePurpose: string;
  paymentMethodName: string;
  paymentMethodId: string;
  paymentMethod: PaymentMethod;
  currencyCode: string;
  remittanceNetAmount: string;
  remittanceAllocatedAmount: string;
  remittanceUnallocatedAmount: string;
  remitStatusId: string;
  operationsOwnerId: number;
  operationsOwnerName: string;
  operationsOwner: OperationsOwner;
  operationsCenterId: number;
  submittedById: number | null;
  approvedById: number | null;
  numberOfLineItems: number;

  constructor(
    remittanceReferenceId?: number,
    remitStatusId?: string,
    facilityGroupId?: number,
    facilityGroupName?: string,
    originatorId?: number,
    originatorName?: string,
    originatorRoleTypeName?: string,
    proposedEffectiveDate?: string | DateTime,
    compartmentId?: number,
    compartmentName?: string,
    receiptDate?: string | DateTime,
    effectiveDate?: string | DateTime,
    remittancePurpose?: string,
    paymentMethodName?: string,
    paymentMethodId?: string,
    currencyCode?: string,
    remittanceNetAmount?: string,
    remittanceAllocatedAmount?: string,
    remittanceUnallocatedAmount?: string,
    operationsOwnerId?: number,
    operationsOwnerName?: string,
    operationsCenterId?: number,
    submittedById?: number | null,
    approvedById?: number | null,
    numberOfLineItems?: number
  ) {
    this.facilityGroupId = facilityGroupId;
    this.facilityGroupName = facilityGroupName;
    this.originatorId = originatorId;
    this.originatorName = originatorName;
    this.originatorRoleTypeName = originatorRoleTypeName;
    this.proposedEffectiveDate = DateUtils.getDateTime(proposedEffectiveDate);
    this.compartmentId = compartmentId;
    this.compartmentName = compartmentName;
    this.receiptDate = DateUtils.getDateTime(receiptDate);
    this.effectiveDate = DateUtils.getDateTime(effectiveDate);
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittancePurpose = remittancePurpose;
    this.paymentMethodName = paymentMethodName;
    this.paymentMethodId = paymentMethodId;
    this.paymentMethod = new PaymentMethod(paymentMethodId, paymentMethodName, true);
    this.currencyCode = currencyCode;
    this.remittanceNetAmount = remittanceNetAmount;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.remitStatusId = remitStatusId;
    this.operationsOwnerId = operationsOwnerId;
    this.operationsOwnerName = operationsOwnerName;
    this.operationsOwner = operationsOwnerId
      ? new OperationsOwner(this.operationsOwnerId, this.operationsOwnerName)
      : null;
    this.operationsCenterId = operationsCenterId;
    this.submittedById = submittedById ?? null;
    this.approvedById = approvedById ?? null;
    this.numberOfLineItems = numberOfLineItems ?? 0;
  }

  public static FromCashAppInboundDetails(cashAppInboundDetails: CashAppInboundDetails) {
    var remit = new InboundRemitGrid();
    remit.remittanceReferenceId = cashAppInboundDetails.remittanceReferenceId;
    remit.receiptDate = cashAppInboundDetails.receiptDate;
    remit.effectiveDate = cashAppInboundDetails.effectiveDate;
    remit.remitStatusId = cashAppInboundDetails.remitStatusId;
    remit.paymentMethod =
      cashAppInboundDetails.paymentMethod instanceof PaymentMethod ? cashAppInboundDetails.paymentMethod : null;
    remit.paymentMethodId = cashAppInboundDetails.paymentMethod?.id?.toString();
    remit.remittanceNetAmount = cashAppInboundDetails.remittanceNetAmount?.toString();
    remit.remittanceAllocatedAmount = cashAppInboundDetails.remittanceAllocatedAmount?.toString();
    remit.operationsOwner = cashAppInboundDetails.operationsOwner;
    remit.operationsOwnerId = cashAppInboundDetails.operationsOwner?.id;
    remit.submittedById = cashAppInboundDetails.submittedById;
    remit.numberOfLineItems = cashAppInboundDetails.lineItems.length;
    return remit;
  }
}
