import { createAction, props } from '@ngrx/store';
import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import {
  ActivePage,
  CashAppInboundDetails,
  ExportRequest,
  InboundRemitGrid,
  Page,
  RemitGridTotals,
  Remittance,
  ManualRemitLineItemData,
  ManualRemitLineItem,
  CashAppRemitLineItem,
  RemitStatusUpdateValidationResult
} from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';

export abstract class CashAppInboundActions extends LsAction {
  /* eslint-disable */
  static CASH_APP_INBOUND: Readonly<string> = 'CASH_APP_INBOUND';
  static CASH_APP_INBOUNDS: Readonly<string> = 'CASH_APP_INBOUNDS';
  static CASH_APP_INBOUND_STATUS: Readonly<string> = 'CASH_APP_INBOUND_STATUS';
  static CASH_APP_INBOUNDS_STATUS: Readonly<string> = 'CASH_APP_INBOUNDS_STATUS';
  static CASH_APP_INBOUND_EXPORT: Readonly<string> = 'EXPORT/CASH_APP_INBOUND';
  static CASH_APP_INBOUND_ACTIVE_PAGE: Readonly<string> = 'CASH_APP_INBOUND_ACTIVE_PAGE';
  static CASH_APP_INBOUND_DETAILS: Readonly<string> = 'CASH_APP_INBOUND_DETAILS';
  static CASH_APP_INBOUND_LINE_ITEMS_EXPORT: Readonly<string> = 'EXPORT/CASH_APP_INBOUND/LINE_ITEMS';
  static CASH_APP_INBOUND_LINE_ITEMS: Readonly<string> = 'CASH_APP_INBOUND_LINE_ITEMS';
  static CASH_APP_INBOUND_MANUAL: Readonly<string> = 'CASH_APP_INBOUND_MANUAL';
  static CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD: Readonly<string> = 'CASH_APP_REMIT_LINE_ITEMS_TO_ADD';
  static CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD_ACTIVE_PAGE: Readonly<string> = 'CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD_ACTIVE_PAGE';
  static CASH_APP_INBOUND_ADD_LINE_ITEMS: Readonly<string> = 'CASH_APP_INBOUND_ADD_LINE_ITEMS';
  static CASH_APP_INBOUND_CHECK_EXISTING_LINE_ITEMS: Readonly<string> = 'CASH_APP_INBOUND_CHECK_EXISTING_LINE_ITEMS';
  static CASH_APP_INBOUND_CHECK_EXISTING_LINE_ITEMS_RESULT: Readonly<string> = 'CASH_APP_INBOUND_CHECK_EXISTING_LINE_ITEMS_RESULT';
  /* eslint-enable */

  static getCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS, ActionType.SET),
    props<{ page: Page<InboundRemitGrid>; totals: RemitGridTotals }>()
  );

  static insertCashAppInboundRemittance = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_MANUAL, ActionType.INSERT),
    props<{ remittance: Remittance }>()
  );

  static insertCashAppInboundRemittanceSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_MANUAL, ActionType.COMPLETE),
    props<{ remittanceId: number }>()
  );

  static insertCashAppInboundRemittanceUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_MANUAL, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getCashAppInbound = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.GET),
    props<{ id: number }>()
  );

  static cashAppInboundSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.COMPLETE)
  );

  static cashAppInboundUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static clearCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.CLEAR)
  );

  static updateCashAppInbound = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND, ActionType.UPDATE),
    props<{ cashAppInbound: InboundRemitGrid; newStatus: string }>()
  );

  static updateCashAppInboundStatus = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_STATUS, ActionType.UPDATE),
    props<{
      cashAppInbound: InboundRemitGrid;
      newStatus: string;
      confirmedValidationResults: RemitStatusUpdateValidationResult[];
    }>()
  );

  static updateCashAppInbounds = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS, ActionType.UPDATE),
    props<{ cashAppInbounds: InboundRemitGrid[]; pageRequest: PageRequest }>()
  );

  static updateCashAppInboundsStatus = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUNDS_STATUS, ActionType.UPDATE),
    props<{
      cashAppInbounds: InboundRemitGrid[];
      newStatus: string;
      confirmedValidationResults: RemitStatusUpdateValidationResult[];
      pageRequest: PageRequest;
    }>()
  );

  static triggerCashAppInboundExport = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static cashAppInboundExportSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_EXPORT, ActionType.COMPLETE)
  );

  static cashAppInboundExportUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static setCashAppInboundActivePage = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static getCashAppInboundDetails = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.GET),
    props<{ id: number }>()
  );

  static setCashAppInboundDetails = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.SET),
    props<{ cashAppInboundDetails: CashAppInboundDetails }>()
  );

  static updateCashAppInboundDetails = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.UPDATE),
    props<{ cashAppInboundDetails: CashAppInboundDetails; newStatus: string }>()
  );

  static cashAppInboundDetailsSaveSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.COMPLETE)
  );

  static cashAppInboundDetailsSaveUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static cashAppInboundLineItemsExport = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS_EXPORT, ActionType.TRIGGER),
    props<{ id: number; exportRequest: ExportRequest }>()
  );

  static cashAppInboundLineItemsExportSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS_EXPORT, ActionType.COMPLETE)
  );

  static cashAppInboundLineItemsExportUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static deleteCashAppInboundRemit = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_DETAILS, ActionType.DELETE),
    props<{ id: number }>()
  );

  static updateCashAppInboundRemitLineItems = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS, ActionType.UPDATE),
    props<{ id: number; lineItems: CashAppRemitLineItem[] }>()
  );

  static deleteCashAppInboundRemitLineItems = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS, ActionType.DELETE),
    props<{ id: number; lineItemSourceIds: number[] }>()
  );

  static cashAppInboundDetailsDeleteSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_LINE_ITEMS, ActionType.COMPLETE)
  );

  static getManualRemittanceLineItemData = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD, ActionType.GET),
    props<{ pageRequest: PageRequest; remitId: number; remitType: string }>()
  );

  static setManualRemittanceLineItemData = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD, ActionType.SET),
    props<{ manualRemitLineItemData: ManualRemitLineItemData }>()
  );

  static clearManualRemittanceLineItemData = createAction(
    CashAppInboundActions.getActionName(
      CashAppInboundActions.CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD,
      ActionType.CLEAR
    )
  );

  static setManualRemittanceLineItemDataActivePage = createAction(
    CashAppInboundActions.getActionName(
      CashAppInboundActions.CASH_APP_INBOUND_REMIT_LINE_ITEMS_TO_ADD_ACTIVE_PAGE,
      ActionType.SET
    ),
    props<{ activePage: ActivePage }>()
  );

  static checkExistingRemitLineItems = createAction(
    CashAppInboundActions.getActionName(
      CashAppInboundActions.CASH_APP_INBOUND_CHECK_EXISTING_LINE_ITEMS,
      ActionType.VALIDATE
    ),
    props<{ remitLineItemType: string; sourceIds: number[] }>()
  );

  static checkExistingRemitLineItemsResults = createAction(
    CashAppInboundActions.getActionName(
      CashAppInboundActions.CASH_APP_INBOUND_CHECK_EXISTING_LINE_ITEMS_RESULT,
      ActionType.RESULT
    ),
    props<{ remitLineItemsExistResult: boolean }>()
  );

  static addManualRemitLineItemsToCashAppInboundRemit = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_ADD_LINE_ITEMS, ActionType.PATCH),
    props<{ remitId: number; lineItemType: string; remitLineItems: ManualRemitLineItem[] }>()
  );

  static addManualRemitLineItemsToCashAppInboundRemitSuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_ADD_LINE_ITEMS, ActionType.COMPLETE)
  );

  static addManualRemitLineItemsToCashAppInboundRemitUnsuccessful = createAction(
    CashAppInboundActions.getActionName(CashAppInboundActions.CASH_APP_INBOUND_ADD_LINE_ITEMS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
