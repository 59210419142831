import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ParticipantOffer, Page, ExportRequest, ActivePage, ParticipantOfferTotals } from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';

export abstract class ParticipantOfferActions extends LsAction {
  /* eslint-disable */
  static PARTICIPANT_OFFERS: Readonly<string> = 'PARTICIPANT_OFFERS';
  static PARTICIPANT_OFFER: Readonly<string> = 'PARTICIPANT_OFFER';
  static PARTICIPANT_OFFERS_EXPORT: Readonly<string> = 'PARTICIPANT_OFFERS/EXPORT';
  static PARTICIPANT_OFFER_POLS_EXPORT: Readonly<string> = 'PARTICIPANT_OFFER_POLS/EXPORT';
  static PARTICIPANT_OFFERS_ACTIVE_PAGE: Readonly<string> = 'PARTICIPANT_OFFERS_ACTIVE_PAGE';
  /* eslint-enable */

  static getParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.SET),
    props<{ page: Page<ParticipantOffer>; totals: ParticipantOfferTotals }>()
  );

  static clearParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.CLEAR)
  );

  static updateParticipantOffer = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER, ActionType.UPDATE),
    props<{ participantOffer: ParticipantOffer; newStatus: string }>()
  );

  static updateParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.UPDATE),
    props<{ participantOffers: ParticipantOffer[]; newStatus: string; pageRequest: PageRequest }>()
  );

  static getParticipantOffer = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER, ActionType.GET),
    props<{ id: number }>()
  );

  static setParticipantOffer = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER, ActionType.SET),
    props<{ participantOffer: ParticipantOffer }>()
  );

  static triggerGenerateParticipantOffer = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER, ActionType.TRIGGER),
    props<{ participantOffer: ParticipantOffer }>()
  );

  static participantOfferSaveSuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER, ActionType.COMPLETE)
  );

  static participantOfferSaveUnsuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static triggerGenerateParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.TRIGGER),
    props<{ participantOffers: ParticipantOffer[]; pageRequest: PageRequest }>()
  );

  static participantOffersSaveSuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.COMPLETE)
  );

  static participantOffersSaveUnsuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static triggerParticipantOffersExport = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static participantOffersExportComplete = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_EXPORT, ActionType.COMPLETE)
  );

  static participantOffersExportUnsuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static triggerParticipantOfferPolsExport = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER_POLS_EXPORT, ActionType.TRIGGER),
    props<{ id: number; exportRequest: ExportRequest }>()
  );

  static participantOfferPolsExportComplete = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER_POLS_EXPORT, ActionType.COMPLETE)
  );

  static participantOfferPolsExportUnsuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFER_POLS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getParticipantOffersActivePage = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_ACTIVE_PAGE, ActionType.GET)
  );

  static setParticipantOffersActivePage = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );
}
