import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { FacilityState } from './facility-reducer';
import { FacilityGroupSelectors } from '../FacilityGroup/facility-group-selectors';
import {
  Facility,
  FacilityGroup,
  InstrumentApprovalStatus,
  LienPosition,
  LimitBasisType,
  MaturityOffset,
  PayXcelType,
  Product
} from '../../../../Models';
import { OpsSelectors } from '../Generic/ops-selectors';

export interface FacilityDetailData {
  activeFacility: Facility;
  activeFacilityGroup: FacilityGroup;
  product: Product;
  lienPositions: LienPosition[];
  limitBasisTypes: LimitBasisType[];
  maturityOffsets: MaturityOffset[];
  payXcelTypes: PayXcelType[];
  instrumentApprovalStatuses: InstrumentApprovalStatus[];
}

@Injectable()
export class FacilitySelectors {
  constructor(private facilityGroupSelectors: FacilityGroupSelectors, private opsSelectors: OpsSelectors) {}
  facilityState = (state: any) => state.facility;

  selectFacilities = createSelector(this.facilityState, (state: FacilityState) =>
    state.facilityPages ? copy(state.facilityPages) : null
  );

  selectActiveFacility = createSelector(this.facilityState, (state: FacilityState) =>
    state.activeFacility ? copy(state.activeFacility) : null
  );

  selectFacilityDetailData = createSelector(
    this.selectActiveFacility,
    this.facilityGroupSelectors.selectActiveFacilityGroup,
    this.opsSelectors.selectSCFProduct,
    this.opsSelectors.selectLienPositions,
    this.opsSelectors.selectLimitBasisTypes,
    this.opsSelectors.selectMaturityOffsets,
    this.opsSelectors.selectPayXcelTypes,
    this.opsSelectors.selectInstrumentApprovalStatuses,
    (
      activeFacility,
      activeFacilityGroup,
      product,
      lienPositions,
      limitBasisTypes,
      maturityOffsets,
      payXcelTypes,
      instrumentApprovalStatuses
    ) => {
      const facilityData: FacilityDetailData = {
        activeFacility,
        activeFacilityGroup,
        product,
        lienPositions,
        limitBasisTypes,
        maturityOffsets,
        payXcelTypes,
        instrumentApprovalStatuses
      };
      return facilityData;
    }
  );

  selectFacilityParticipantGrid = createSelector(this.facilityState, (state: FacilityState) =>
    state.facilityParticipantConfigGrid ? copy(state.facilityParticipantConfigGrid) : null
  );

  selectActiveFacilityParticipantConfig = createSelector(this.facilityState, (state: FacilityState) =>
    state.activeFacilityParticipantConfig ? copy(state.activeFacilityParticipantConfig) : null
  );

  selectFacilitiesActivePage = createSelector(this.facilityState, (state) =>
    state.facilitiesActivePage ? copy(state?.facilitiesActivePage) : null
  );

  selectFacilitiesComponentState = createSelector(
    this.selectFacilities,
    this.selectFacilitiesActivePage,
    (facilityPages, facilitiesActivePage) => {
      return {
        facilityPages: facilityPages,
        facilitiesActivePage: facilitiesActivePage
      };
    }
  );

  selectFacilityParticipantConfigsComponentState = createSelector(this.facilityState, (state: FacilityState) => ({
    facilityParticipantConfigPages: copy(state?.facilityParticipantConfigPages),
    facilityParticipantConfigsActivePage: copy(state?.facilityParticipantConfigsActivePage)
  }));

  selectExistingFacilities = createSelector(this.facilityState, (state: FacilityState) =>
    state.existingFacilityPages! ? copy(state.existingFacilityPages) : []
  );
}
