import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';
import { SortDirection } from '@limestone/ls-shared-modules';

export class RemitParticipantDisbursementConfig extends ReportConfig {
  constructor() {
    super();
    this.reportType = ReportTypes.RM_PT_DIS;
    this.gridColumns = [
      {
        label: 'Effective Date',
        value: 'effectiveDate',
        dataType: 'date',
        enableFilter: true
      },
      {
        label: 'Recipient Name',
        value: 'recipientName',
        dataType: 'text'
      },
      {
        label: 'Remittance Reference ID',
        value: 'id',
        dataType: 'text',
        enableFilter: true
      },
      {
        label: 'Remittance Purpose',
        value: 'remittancePurpose',
        dataType: 'text'
      },
      {
        label: 'Remittance Type',
        value: 'remittanceType',
        dataType: 'text'
      },
      {
        label: 'Remittance Method',
        value: 'remittanceMethod',
        dataType: 'text'
      },
      {
        label: 'Remittance Net Amount',
        value: 'remittanceNetAmount',
        dataType: 'number'
      },
      {
        label: 'Remittance Allocated Amount',
        value: 'remittanceAllocatedAmount',
        dataType: 'number'
      },
      {
        label: 'Remittance Unallocated Amount',
        value: 'remittanceUnallocatedAmount',
        dataType: 'number'
      }
    ];
    this.activePage = this.buildActivePageConfig([
      { active: 'effectiveDate', direction: SortDirection.ASC },
      { active: 'id', direction: SortDirection.ASC }
    ]);
  }
}
