import { Injectable } from '@angular/core';
import copy from 'fast-copy';
import { Page } from 'src/app/Models';
import { ActiveFilter, GridSort, SortDirection } from '@limestone/ls-shared-modules';
import { DateUtils } from '../../../../../Utils/DateUtils';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DataGridService {
  public objectDelimiter: Readonly<string> = '/';

  private filterResult(data: any, filter: ActiveFilter): boolean {
    const isArray = filter.filterType === 'select';
    let filterValue = isArray ? filter.filterValue.toString().split('|') : filter.filterValue;

    if (typeof data === 'undefined' || data === null) {
      return false;
    }

    if (filter.filterType === 'date' || filter.filterType === 'datetime') {
      data = DateUtils.getDateTime(data).toLocaleString(DateTime.DATE_SHORT);
    }

    if (typeof data === 'string') {
      data = data.trim().toLowerCase();
    }

    if (typeof data === 'boolean') {
      filterValue = filterValue === '1';
    }

    if (typeof filterValue === 'string') {
      filterValue = filterValue.toLowerCase();
    }

    if (isArray) {
      filterValue.forEach((element, index, array) => {
        if (typeof element === 'string') {
          array[index] = element.toLowerCase();
        }
      });
    }

    switch (filter.filterAction) {
      case 'Equals':
        const equalsData = data.hasOwnProperty('id') ? data.id : data;
        return isArray ? filterValue.some((fv) => equalsData.toLowerCase().includes(fv)) : data === filterValue;

      case 'Not Equals':
        const notEqualsData = data.hasOwnProperty('id') ? data.id : data;
        return isArray ? !filterValue.some((fv) => notEqualsData.toLowerCase().includes(fv)) : data !== filterValue;

      case 'Contains':
        return isArray ? filterValue.some((fv) => data.toString().includes(fv)) : data.toString().includes(filterValue);
      case 'Not Contains':
        return isArray
          ? !filterValue.some((fv) => !data.toString().includes(fv))
          : !data.toString().includes(filterValue);
      case 'Starts With':
        return data.startsWith(filterValue);
      case 'Ends With':
        return data.toString().endsWith(filterValue);
      case 'Greater Than':
        return data > filterValue;
      case 'Less Than':
        return data < filterValue;
      case 'Greater Than or Equal To':
        return data >= filterValue;
      case 'Less Than or Equal To':
        return data <= filterValue;
      default:
        break;
    }
  }

  sort(data: any[], gridSort: GridSort[], caseInsensitive: boolean = false): any[] {
    let sortedData;
    sortedData = copy(data).sort((a, b) => {
      for (let sort of gridSort) {
        let curr = this.getElementValueFromColumnValue(sort.active, a);
        let next = this.getElementValueFromColumnValue(sort.active, b);
        if (caseInsensitive) {
          curr = curr != null && typeof curr === 'string' ? curr.toLowerCase() : curr;
          next = next != null && typeof next === 'string' ? next.toLowerCase() : next;
        }
        if (sort.direction === SortDirection.ASC) {
          if (curr > next) {
            return 1;
          }
          if (curr < next) {
            return -1;
          }
          if (curr === null && next !== null) {
            return -1;
          }
          if (curr !== null && next === null) {
            return 1;
          }
        } else if (sort.direction === SortDirection.DESC) {
          if (curr < next) {
            return 1;
          }
          if (curr > next) {
            return -1;
          }
          if (curr === null && next !== null) {
            return 1;
          }
          if (curr !== null && next === null) {
            return -1;
          }
        }
      }
      return 0;
    });
    return sortedData;
  }

  filter(data: any[], filterSet: Map<string, ActiveFilter[]>, column?: string): any[] {
    let dataCopy = copy(data);
    filterSet.forEach((filters, key) => {
      dataCopy = dataCopy.filter((d) => {
        if (filters.length === 2) {
          if (filters[0].filterJunction) {
            if (filters[0].filterJunction === 'AND') {
              return (
                this.filterResult(this.getElementValueFromColumnValue(key, d), filters[0]) &&
                this.filterResult(this.getElementValueFromColumnValue(key, d), filters[1])
              );
            } else {
              return (
                this.filterResult(this.getElementValueFromColumnValue(key, d), filters[0]) ||
                this.filterResult(this.getElementValueFromColumnValue(key, d), filters[1])
              );
            }
          }
        } else if (filters.length === 1) {
          const filter = filters[0];
          return this.filterResult(this.getElementValueFromColumnValue(key, d), filter);
        } else {
          // Nothing to filter.
        }
      });
    });
    return dataCopy;
  }

  getElementValueFromColumnValue(columnValue: string, element: any): any {
    if (columnValue === null || columnValue === '') {
      return element;
    }
    const propNames = columnValue.split(this.objectDelimiter);
    let elementValue = element;
    for (const propName of propNames) {
      if (!!elementValue) {
        elementValue = elementValue[propName];
      }
    }
    return elementValue;
  }

  setElementValueFromColumnValue(element: any, columnValue: string, value: any) {
    let schema = element;
    const properties = columnValue.split(this.objectDelimiter);
    for (let i = 0; i < properties.length - 1; i++) {
      const property = properties[i];
      if (!schema[property]) {
        schema[property] = {};
      }
      schema = schema[property];
    }
    const lastProp = properties[properties.length - 1];
    schema[lastProp] = value;
  }

  setChildObjectPath(pathToChild: string[]): string {
    return pathToChild.join(this.objectDelimiter);
  }

  convertToPaginatedData(data: any[], pageSize: number): Array<Page<any>> {
    const pages = new Array<Page<any>>();
    if (data.length === 0) {
      const page = new Page<any>();
      page.totalElements = data.length;
      page.size = pageSize;
      page.content = data;
      pages.push(page);
    }

    for (let i = 0; i < data.length; i += pageSize) {
      const page = new Page<any>();
      page.totalElements = data.length;
      page.size = pageSize;
      const length = i + pageSize < data.length ? i + pageSize : data.length;
      page.content = data.slice(i, length);
      pages.push(page);
    }

    return pages;
  }
}
