import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Guid } from '../../../../../Utils/Guid';
import { ActiveFilter, DropdownOption, GridFilterColumnDataTypes } from '@limestone/ls-shared-modules';
import { LargeCurrencyDisplayPipe } from '../../../../../Utils/Pipes';
import { NumberValidation, RequiredValidation } from '../Models/LsDataGridConfig';
import { DateTime } from 'luxon';
import { DateUtils } from '../../../../../Utils/DateUtils';

@Component({
  selector: 'ls-grid-filter-modal',
  templateUrl: './grid-filter-modal.component.html',
  styleUrls: ['./grid-filter-modal.component.scss']
})
export class GridFilterModalComponent implements OnInit {
  filterForm = new UntypedFormGroup({});
  controls = [];
  filterActions = [];

  constructor(
    public dialogRef: MatDialogRef<GridFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GridFilter,
    @Inject(Guid) public guid: Guid,
    private largeCurrencyDisplayPipe: LargeCurrencyDisplayPipe
  ) {}

  ngOnInit() {
    this.filterActions = this.getFilterOptions(this.data.columnDataType);
    if (this.data.filters.length === 0) {
      this.addFormGroup();
    } else {
      this.applyActiveFilters(this.data.filters);
    }
  }

  addFormGroup(formValues?: ActiveFilter): void {
    const guid = this.guid.New();
    let value = formValues?.filterValue ?? '';
    if ((this.data.columnDataType === 'date' || this.data.columnDataType === 'datetime') && !!formValues) {
      value = DateTime.fromFormat(formValues?.filterValue, 'MM/dd/yyyy');
    } else if (this.data.columnDataType === 'select' && !!formValues) {
      value = formValues.filterValue.split('|').map((fv) => this.data.columnOptions.find((c) => c.value().id === fv));
    }

    this.filterForm.addControl(
      `${guid}`,
      new UntypedFormGroup({
        filterAction: new UntypedFormControl(
          formValues?.filterAction ?? (this.data.columnDataType === 'checkbox' ? 'Equals' : '')
        ),
        filterValue: new UntypedFormControl(value, Validators.required),
        filterJunction: new UntypedFormControl(formValues?.filterJunction ?? '')
      })
    );
    if (this.data.columnDataType !== 'checkbox') {
      this.filterForm.get(guid).get('filterAction').setValidators(Validators.required);
    }
    this.controls.push(guid);
  }

  applyActiveFilters(filters: ActiveFilter[]): void {
    filters.forEach((filter) => this.addFormGroup(filter));
  }

  closeModal(): void {
    const outputData: ActiveFilter[] = [];
    this.controls.forEach((control) => {
      const form = this.filterForm.get(control);
      let value = form.value.filterValue;
      switch (this.data.columnDataType) {
        case 'date':
        case 'datetime':
          value = DateUtils.getDateTime(form.value.filterValue).toLocaleString({
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          });
          break;
        case 'number':
          if (this.data.columnValidation instanceof NumberValidation) {
            value = this.largeCurrencyDisplayPipe.transform(value, this.data.columnValidation);
          } else {
            value = +value;
          }
          break;
        case 'select':
          value = form.value.filterValue.reduce((previousValue, currentValue, index) => {
            return index > 0 ? previousValue + '|' + currentValue.id : currentValue.id;
          }, form.value.filterValue[0].id);
        default:
      }

      const dataOut: ActiveFilter = {
        filterAction: form.value.filterAction,
        filterValue: value,
        filterJunction: form.value.filterJunction,
        filterType: this.data.columnDataType
      };
      outputData.push(dataOut);
    });
    this.dialogRef.close(outputData);
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  getFilterOptions(column: string): string[] {
    if (this.data.columnDataType === 'text') {
      return ['Equals', 'Not Equals', 'Contains', 'Not Contains', 'Starts With', 'Ends With'];
    } else if (
      this.data.columnDataType === 'number' ||
      this.data.columnDataType === 'date' ||
      this.data.columnDataType === 'datetime'
    ) {
      return ['Equals', 'Not Equals', 'Greater Than', 'Less Than', 'Greater Than or Equal To', 'Less Than or Equal To'];
    } else if (this.data.columnDataType === 'select' || this.data.columnDataType === 'autocomplete') {
      if (this.data.allowContains) {
        return ['Equals', 'Not Equals', 'Contains', 'Not Contains'];
      } else {
        return ['Equals', 'Not Equals'];
      }
    }
    return [];
  }

  isLast(control: string): boolean {
    return this.controls.indexOf(control) > -1 && this.controls.indexOf(control) === this.controls.length - 1;
  }

  maxNumOfControls(): boolean {
    return this.data.columnDataType === 'checkbox' ? true : this.controls.length === 2;
  }

  removeForm(form: string): void {
    this.filterForm.removeControl(form.toString());
    this.controls = this.controls.filter((f) => f !== form);
    if (this.controls.length === 1) {
      this.filterForm.get(this.controls[0]).get('filterJunction').setValue(null);
    }
  }
}

export interface GridFilter {
  column: string;
  columnValueName: string;
  columnDataType: GridFilterColumnDataTypes;
  filters?: ActiveFilter[];
  columnOptions?: DropdownOption[];
  allowContains?: boolean;
  columnValidation?: RequiredValidation;
}
