import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';
import { SortDirection } from '@limestone/ls-shared-modules';

export class AccountingParticipantCashApplicationConfig extends ReportConfig {
  constructor() {
    super();
    this.reportType = ReportTypes.AC_PT_CA;
    this.gridColumns = [
      {
        label: 'Instrument ID',
        value: 'instrumentId',
        dataType: 'text'
      },
      {
        label: 'Seller Name',
        value: 'sellerName',
        dataType: 'text'
      },
      {
        label: 'Seller Country',
        value: 'sellerCountry',
        dataType: 'text'
      },
      {
        label: 'Debtor Name',
        value: 'debtorName',
        dataType: 'text'
      },
      {
        label: 'Instrument Type',
        value: 'instrumentType',
        dataType: 'text'
      },
      {
        label: 'Document ID',
        value: 'documentId',
        dataType: 'text'
      },
      {
        label: 'Currency',
        value: 'currency',
        dataType: 'text'
      },
      {
        label: 'Net Amount',
        value: 'netAmount',
        dataType: 'number'
      },
      {
        label: 'Advanced Amount',
        value: 'advancedAmount',
        dataType: 'number'
      },
      {
        label: 'Purchase Price - Participant',
        value: 'purchasePrice',
        dataType: 'number'
      },
      {
        label: 'Issue Date - Seller',
        value: 'issueDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy'
      },
      {
        label: 'Expected Due Date',
        value: 'expectedDueDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy',
        enableFilter: true
      },
      {
        label: 'Reserve Amount',
        value: 'reserveAmount',
        dataType: 'number'
      },
      {
        label: 'Participant Discount',
        value: 'participantDiscount',
        dataType: 'number'
      },
      {
        label: 'Monthly Rate',
        value: 'monthlyRate',
        dataType: 'number'
      },
      {
        label: 'Buffer Days',
        value: 'bufferDays',
        dataType: 'number'
      },
      {
        label: 'Advance Rate',
        value: 'advanceRate',
        dataType: 'percent'
      },
      {
        label: 'Collection Date',
        value: 'collectionDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy'
      },
      {
        label: 'Collection Amount',
        value: 'collectionAmount',
        dataType: 'number'
      },
      {
        label: 'Last Collection Date',
        value: 'lastCollectionDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy'
      },
      {
        label: 'Participant Name',
        value: 'participantName',
        dataType: 'text'
      },
      {
        label: 'Participant Balance',
        value: 'participantBalance',
        dataType: 'number'
      }
    ];
    this.activePage = this.buildActivePageConfig([
      { active: 'expectedDueDate', direction: SortDirection.ASC },
      { active: 'instrumentId', direction: SortDirection.ASC }
    ]);
  }
}
