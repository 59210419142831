import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';
import { SortDirection } from '@limestone/ls-shared-modules';

export class AccountingBuySellReportConfig extends ReportConfig {
  constructor() {
    super();
    this.reportType = ReportTypes.AC_BUY_SEL;
    this.gridColumns = [
      {
        label: 'POL ID',
        value: 'polId',
        dataType: 'number'
      },
      {
        label: 'Purchase Date',
        value: 'purchaseDate',
        dataType: 'date',
        enableFilter: true
      },
      {
        label: 'Month',
        value: 'month',
        dataType: 'number'
      },
      {
        label: 'Participant Name',
        value: 'participantName',
        dataType: 'text'
      },
      {
        label: 'Seller Name',
        value: 'sellerName',
        dataType: 'text'
      },
      {
        label: 'Debtor Name',
        value: 'debtorName',
        dataType: 'text'
      },
      {
        label: 'Net Amount',
        value: 'netAmount',
        dataType: 'number'
      },
      {
        label: 'Seller Purchase Price',
        value: 'sellerPurchasePrice',
        dataType: 'number'
      },
      {
        label: 'Participant Purchase Price',
        value: 'participantPurchasePrice',
        dataType: 'number'
      },
      {
        label: 'Seller Discount',
        value: 'sellerDiscount',
        dataType: 'number'
      },
      {
        label: 'Participant Discount',
        value: 'participantDiscount',
        dataType: 'number'
      },
      {
        label: 'Buy/Sell Spread',
        value: 'buySellSpread',
        dataType: 'number'
      }
    ];
    this.activePage = this.buildActivePageConfig([{ active: 'purchaseDate', direction: SortDirection.ASC }]);
  }
}
