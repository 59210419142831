import { formatDate, formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LargeCurrencyDisplayPipe } from '../../../../Utils/Pipes';
import { NumberValidation } from '../data-grid/Models/LsDataGridConfig';

@Component({
  selector: 'ls-key-value',
  templateUrl: './key-value.component.html',
  styleUrls: ['./key-value.component.scss']
})
export class KeyValueComponent {
  @Input() label: string;
  @Input() value: any;
  @Input() dataType: 'percent' | 'rate' | 'date' | 'number' | 'string' | 'link' | 'currency' = 'string';
  // top-bottom, bottom-top
  @Input() orientation: 'top-bottom' | 'bottom-top' = 'bottom-top';
  // x-small, small, medium, large
  @Input() size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() currencyCode: string;
  @Input() timeZone: string;
  @Input() warning: string;
  @Input() showWarning: boolean = false;

  @Output() linkClick: EventEmitter<string> = new EventEmitter<string>();
  constructor(private largeCurrencyDisplayPipe: LargeCurrencyDisplayPipe) {}

  formatData(el: any) {
    if (el === null) {
      return el;
    }
    switch (this.dataType) {
      case 'date':
        const timeZone = this.timeZone ? this.timeZone : 'Local';
        return formatDate(el, 'mediumDate', 'en-US', timeZone);
      case 'percent':
      case 'rate':
        return formatNumber(el * 100, 'en-US', '1.2-2') + '%';
      case 'number':
        return this.largeCurrencyDisplayPipe.transform(el, new NumberValidation(undefined, 2));
      case 'currency':
        return `${this.currencyCode} ${this.largeCurrencyDisplayPipe.transform(
          el,
          new NumberValidation(undefined, 2)
        )}`;
      default:
        return this.value;
    }
  }

  handleLinkClicked($event: MouseEvent) {
    if (this.dataType === 'link') {
      this.linkClick.emit(this.label);
    }
  }

  /**
   * Gets the tool tip value. Will apply transformations if applicable.
   *
   * @return string | number formatted cell tool tip value.
   */
  public getToolTip(el: any): string | number {
    if (el === null) {
      return el;
    }
    switch (this.dataType) {
      case 'number':
        return formatNumber(el, 'en-US', '1.2-2');
      default:
        return null;
    }
  }
}
