export class Report {
  id?: number;
  name?: string;
  description?: string;
  integrationTypeName?: string;
  fileName?: string;
  reportDate?: string;
  generatedBy?: string;

  constructor(
    id?: number,
    name?: string,
    description?: string,
    integrationTypeName?: string,
    fileName?: string,
    reportDate?: string,
    generatedBy?: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.integrationTypeName = integrationTypeName;
    this.fileName = fileName;
    this.reportDate = reportDate;
    this.generatedBy = generatedBy;
  }
}
