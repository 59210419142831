export class ExistingFacility {
  id: number;
  debtorCompanyName: string;
  sellerCompanyName: string;
  lienPositionName: string;
  payXcelTypeName: string;
  isAutoXcelEnabled: boolean;
  channelName: string;

  constructor(
    id: number,
    debtorCompanyName: string,
    sellerCompanyName: string,
    lienPositionName: string,
    payXcelTypeName: string,
    isAutoXcelEnabled: boolean,
    channelName: string
  ) {
    this.id = id;
    this.debtorCompanyName = debtorCompanyName;
    this.sellerCompanyName = sellerCompanyName;
    this.lienPositionName = lienPositionName;
    this.payXcelTypeName = payXcelTypeName;
    this.isAutoXcelEnabled = isAutoXcelEnabled;
    this.channelName = channelName;
  }
}
