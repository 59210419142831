import { ReportConfig } from './ReportConfig';
import { ReportTypes } from '../ReportTypes';
import { SellerInstrumentListConfig } from './SellerInstrumentListConfig';
import { SellerOnboardingConfig } from './SellerOnboardingConfig';
import { RemitSellerCollectionConfig } from './RemitSellerCollectionConfig';
import { LineItemSellerCollectionConfig } from './LineItemSellerCollectionConfig';
import { SellerPolListConfig } from './SellerPolListConfig';
import { LineItemsSellerDisbursementConfig } from './LineItemsSellerDisbursementConfig';
import { MaturingPaymentConfig } from './MaturingPaymentConfig';
import { RemitSellerDisbursementConfig } from './RemitSellerDisbursementConfig';
import { AccountingParticipantCashApplicationConfig } from './AccountingParticipantCashApplicationConfig';
import { RemitParticipantDisbursementConfig } from './RemitParticipantDisbursementConfig';
import { RemitParticipantCollectionConfig } from './RemitParticipantCollectionConfig';
import { AccountingBuySellReportConfig } from './AccountingBuySellReportConfig';
import { LineItemsParticipantDisbursementConfig } from './LineItemsParticipantDisbursementConfig';

export class ReportConfigFactory {
  static createConfig(reportType: ReportTypes): ReportConfig {
    switch (reportType) {
      case ReportTypes.AC_BUY_SEL:
        return new AccountingBuySellReportConfig();
      case ReportTypes.AC_PT_CA:
        return new AccountingParticipantCashApplicationConfig();
      case ReportTypes.LI_PT_DIS:
        return new LineItemsParticipantDisbursementConfig();
      case ReportTypes.LI_SEL_COL:
        return new LineItemSellerCollectionConfig();
      case ReportTypes.LI_SEL_DIS:
        return new LineItemsSellerDisbursementConfig();
      case ReportTypes.MAT_PAYMT:
        return new MaturingPaymentConfig();
      case ReportTypes.RM_PT_COL:
        return new RemitParticipantCollectionConfig();
      case ReportTypes.RM_PT_DIS:
        return new RemitParticipantDisbursementConfig();
      case ReportTypes.RM_SEL_COL:
        return new RemitSellerCollectionConfig();
      case ReportTypes.RM_SEL_DIS:
        return new RemitSellerDisbursementConfig();
      case ReportTypes.SELL_INST:
        return new SellerInstrumentListConfig();
      case ReportTypes.SELL_ONBD:
        return new SellerOnboardingConfig();
      case ReportTypes.SELL_POL:
        return new SellerPolListConfig();
      default:
        throw new Error(`Report type ${reportType} is not supported`);
    }
  }
}
