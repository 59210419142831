import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import {
  Facility,
  FacilityList,
  Page,
  FacilityParticipantConfiguration,
  ActivePage,
  ExistingFacility
} from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';
import { ExistingFacilitiesCriteria } from '../../Components';

export abstract class FacilityActions extends LsAction {
  static FACILITIES: Readonly<string> = 'FACILITIES';
  static FACILITY: Readonly<string> = 'FACILITY';
  static FACILITIES_ACTIVE_PAGE: Readonly<string> = 'FACILITIES_ACTIVE_PAGE';
  static FACILITY_PARTICIPANT_CONFIG: Readonly<string> = 'FACILITY_PARTICIPANT_CONFIG';
  static FACILITY_PARTICIPANT_CONFIGS: Readonly<string> = 'FACILITY_PARTICIPANT_CONFIGS';
  static FACILITY_PARTICIPANT_CONFIGS_ACTIVE_PAGE: Readonly<string> = 'FACILITY_PARTICIPANT_CONFIGS_ACTIVE_PAGE';
  static EXISTING_FACILITIES: Readonly<string> = 'EXISTING_FACILITIES';

  static getFacilities = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setFacilities = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.SET),
    props<{ page: Page<FacilityList> }>()
  );

  static getFacility = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY, ActionType.GET),
    props<{ id: number }>()
  );

  static setFacility = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY, ActionType.SET),
    props<{ facility: Facility }>()
  );

  static clearFacilities = createAction(FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.CLEAR));

  static createFacility = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.SAVE),
    props<{ facility: Facility }>()
  );

  static updateFacility = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.UPDATE),
    props<{ facility: Facility }>()
  );

  static approveFacility = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY, ActionType.APPROVE),
    props<{ facility: Facility }>()
  );

  static facilitySaveSuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.COMPLETE)
  );

  static facilitySaveUnsuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getFacilitiesActivePage = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES_ACTIVE_PAGE, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setFacilitiesActivePage = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static patchFacility = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY, ActionType.PATCH),
    props<{ facilityId: number; facilityGroupId: number; patchOperations: any }>()
  );

  static facilityPatchUnsuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  //FacilityParticipantConfig

  static getFacilityParticipantConfig = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.GET),
    props<{ id: number; facilityConfigId: number; facilityId: number }>()
  );

  static setFacilityParticipantConfig = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.SET),
    props<{ facilityParticipantConfig: FacilityParticipantConfiguration }>()
  );

  static createFacilityParticipantConfig = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.SAVE),
    props<{ facilityParticipantConfig: FacilityParticipantConfiguration }>()
  );

  static updateFacilityParticipantConfig = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.UPDATE),
    props<{ facilityParticipantConfig: FacilityParticipantConfiguration }>()
  );

  static facilityParticipantConfigSaveSuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.COMPLETE)
  );

  static facilityParticipantConfigSaveUnsuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getFacilityParticipantConfigsActivePage = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIGS_ACTIVE_PAGE, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setFacilityParticipantConfigsActivePage = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIGS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static clearFacility = createAction(FacilityActions.getActionName(FacilityActions.FACILITY, ActionType.CLEAR));

  static clearFacilityParticipantConfig = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIG, ActionType.CLEAR)
  );

  static clearFacilityParticipantConfigs = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITY_PARTICIPANT_CONFIGS, ActionType.CLEAR)
  );

  static getExistingFacilitiesPage = createAction(
    FacilityActions.getActionName(FacilityActions.EXISTING_FACILITIES, ActionType.GET),
    props<{ criteria: ExistingFacilitiesCriteria; pageRequest: PageRequest }>()
  );

  static setExistingFacilitiesPage = createAction(
    FacilityActions.getActionName(FacilityActions.EXISTING_FACILITIES, ActionType.SET),
    props<{ existingFacilitiesPage: Page<ExistingFacility> }>()
  );

  static clearExistingFacilitiesPages = createAction(
    FacilityActions.getActionName(FacilityActions.EXISTING_FACILITIES, ActionType.CLEAR)
  );

  static addFacilitiesToGroup = createAction(
    FacilityActions.getActionName(FacilityActions.FACILITIES, ActionType.PATCH),
    props<{ facilityGroupId: number; facilities: number[] }>()
  );

  static existingFacilitiesPatchSuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.EXISTING_FACILITIES, ActionType.COMPLETE)
  );

  static existingFacilitiesPatchUnsuccessful = createAction(
    FacilityActions.getActionName(FacilityActions.EXISTING_FACILITIES, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
