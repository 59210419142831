// @ts-ignore

export class RemitLineItem {
  lineItemId: number;
  polId?: number;
  instrument: string;
  instrumentId?: number;
  seller: string;
  sellerId?: number;
  debtor: string;
  debtorId?: number;
  participant: string;
  lineItemType: string;
  currency: string;
  lineItemAllocatedAmount: number;
  facilityGroupId?: number;
  lineItemExpectedAmount: number;

  constructor(
    lineItemId?: number,
    polId?: number,
    instrument?: string,
    instrumentId?: number,
    seller?: string,
    sellerId?: number,
    debtor?: string,
    debtorId?: number,
    participant?: string,
    lineItemType?: string,
    currency?: string,
    lineItemAllocatedAmount?: number,
    facilityGroupId?: number,
    lineItemExpectedAmount?: number
  ) {
    this.lineItemId = lineItemId;
    this.polId = polId;
    this.instrument = instrument;
    this.instrumentId = instrumentId;
    this.seller = seller;
    this.sellerId = sellerId;
    this.debtor = debtor;
    this.debtorId = debtorId;
    this.participant = participant;
    this.lineItemType = lineItemType;
    this.currency = currency;
    this.lineItemAllocatedAmount = lineItemAllocatedAmount;
    this.facilityGroupId = facilityGroupId;
    this.lineItemExpectedAmount = lineItemExpectedAmount;
  }
}
