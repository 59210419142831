import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { Pob, Page, PobView, PobFinancingStatus, ActivePage, ExportRequest } from '../../../../Models';
import { PageRequest } from '@limestone/ls-shared-modules';

export abstract class PobActions extends LsAction {
  /* eslint-disable */
  static POBS: Readonly<string> = 'POBS';
  static POB: Readonly<string> = 'POB';
  static POB_FINANCING_STATUS: Readonly<string> = 'POB_FINANCING_STATUSES';
  static POBS_ACTIVE_PAGE: Readonly<string> = 'POBS_ACTIVE_PAGE';
  static POBS_EXPORT: Readonly<string> = 'POBS/EXPORT';
  /* eslint-enable */

  static getPobs = createAction(
    PobActions.getActionName(PobActions.POBS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setPobs = createAction(
    PobActions.getActionName(PobActions.POBS, ActionType.SET),
    props<{ page: Page<Pob> }>()
  );

  static updatePobs = createAction(
    PobActions.getActionName(PobActions.POBS, ActionType.UPDATE),
    props<{ pobs: Pob[] }>()
  );

  static pobSaveSuccessful = createAction(PobActions.getActionName(PobActions.POBS, ActionType.COMPLETE));

  static pobSaveUnsuccessful = createAction(
    PobActions.getActionName(PobActions.POBS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getPobFinancingStatuses = createAction(
    PobActions.getActionName(PobActions.POB_FINANCING_STATUS, ActionType.GET)
  );

  static setPobFinancingStatuses = createAction(
    PobActions.getActionName(PobActions.POB_FINANCING_STATUS, ActionType.SET),
    props<{ pobFinancingStatuses: PobFinancingStatus[] }>()
  );

  static clearPobs = createAction(PobActions.getActionName(PobActions.POBS, ActionType.CLEAR));

  static getPob = createAction(PobActions.getActionName(PobActions.POB, ActionType.GET), props<{ id: number }>());
  static setPob = createAction(PobActions.getActionName(PobActions.POB, ActionType.SET), props<{ pob: PobView }>());
  static clearPob = createAction(PobActions.getActionName(PobActions.POB, ActionType.CLEAR));

  static getPobsActivePage = createAction(PobActions.getActionName(PobActions.POBS_ACTIVE_PAGE, ActionType.GET));

  static setPobsActivePage = createAction(
    PobActions.getActionName(PobActions.POBS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static triggerPobsExport = createAction(
    PobActions.getActionName(PobActions.POBS_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static pobsExportComplete = createAction(PobActions.getActionName(PobActions.POBS_EXPORT, ActionType.COMPLETE));

  static pobsExportUnsuccessful = createAction(
    PobActions.getActionName(PobActions.POBS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
