import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { GridSort, PageRequest, ActiveFilter as SharedActiveFilter, ActiveFilter } from '@limestone/ls-shared-modules';

export class ActivePage {
  page?: PageEvent;
  sort?: GridSort[];
  filter?: Map<string, ActiveFilter[]> | Map<string, SharedActiveFilter[]>;
  status?: string;

  constructor(
    page?: PageEvent,
    sort?: GridSort[],
    filter?: Map<string, ActiveFilter[]> | Map<string, SharedActiveFilter[]>,
    status?: string
  ) {
    this.page = page ?? null;
    this.sort = sort ?? null;
    this.filter = filter ?? null;
    this.status = status ?? null;
  }

  toPageRequest(): PageRequest {
    return new PageRequest(
      this.page?.pageIndex ? this.page?.pageIndex + 1 : 1,
      this.page?.pageSize ?? 25,
      this.sort,
      this.filter
    );
  }
}
