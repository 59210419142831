import { SortDirection } from '@limestone/ls-shared-modules';
import { ReportTypes } from '../ReportTypes';
import { ReportConfig } from './ReportConfig';

export class MaturingPaymentConfig extends ReportConfig {
  constructor() {
    super();
    this.reportType = ReportTypes.MAT_PAYMT;
    this.gridColumns = [
      {
        label: 'POL ID',
        value: 'polId',
        dataType: 'text'
      },
      {
        label: 'Seller Name',
        value: 'sellerName',
        dataType: 'text'
      },
      {
        label: 'Debtor Name',
        value: 'debtorName',
        dataType: 'text'
      },
      {
        label: 'Instrument Type',
        value: 'instrumentType',
        dataType: 'text'
      },
      {
        label: 'Document ID',
        value: 'documentId',
        dataType: 'text'
      },
      {
        label: 'Currency',
        value: 'currency',
        dataType: 'text'
      },
      {
        label: 'Seller/Debtor Balance',
        value: 'sellerDebtorBalance',
        dataType: 'number'
      },
      {
        label: 'POL Eligibility Status',
        value: 'polEligibilityStatus',
        dataType: 'text'
      },
      {
        label: 'Issue Date - Seller',
        value: 'issueDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy'
      },
      {
        label: 'Expected Due Date',
        value: 'expectedDueDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy',
        enableFilter: true
      },
      {
        label: 'Settlement Date',
        value: 'settlementDate',
        dataType: 'datetime',
        dateTimeFormat: 'MM-dd-yyyy'
      },
      {
        label: 'Seller Discount Rate',
        value: 'sellerDiscountRate',
        dataType: 'number'
      },
      {
        label: 'Seller Purchase Price',
        value: 'sellerPurchasePrice',
        dataType: 'number'
      },
      {
        label: 'Seller Discount',
        value: 'sellerDiscount',
        dataType: 'number'
      },
      {
        label: 'Participant Name',
        value: 'participantName',
        dataType: 'text'
      }
    ];
    this.activePage = this.buildActivePageConfig([{ active: 'expectedDueDate', direction: SortDirection.ASC }]);
  }
}
